'use client';

export const getCookie = (cookieName: string): string | null => {
  if (typeof document === 'undefined') return null;
  const match = document.cookie.match(new RegExp(`(^| )${cookieName}=([^;]+)`));
  return match ? decodeURIComponent(match[2] ?? '') : null;
};

export const setCookie = (cookieName: string, value: string, lifetimeDays: number = 7): void => {
  if (typeof document === 'undefined') return;
  const expires = new Date();
  expires.setTime(expires.getTime() + lifetimeDays * 24 * 60 * 60 * 1000);
  document.cookie = `${cookieName}=${encodeURIComponent(value)}; path=/; expires=${expires.toUTCString()}; SameSite=None; Secure`;
};

export function deleteCookie(cookieName: string): void {
  document.cookie = `${cookieName}=; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC; SameSite=Lax`;
}

export const getAllCookies = (): Record<string, string> => {
  const result: Record<string, string> = {};
  const cookieStr = document.cookie;
  const cookies = cookieStr.split("; ");

  for (const cookie of cookies) {
    const [key, value] = cookie.split("=");
    if (key) result[key] = decodeURIComponent(value);
  }

  return result;
}

export const buildCookieString = (): string => {
  const cookies = getAllCookies();
  return Object.entries(cookies).map(([key, value]) => `${key}=${encodeURIComponent(value)}`).join("; ");
}
