'use client';

import { ReactNode, useCallback } from 'react';
import { addItem, removeItem } from '@/store/cartSlice';
import { AppDispatch, RootState } from '@/store/store';
import { useDispatch, useSelector } from 'react-redux';
import type { IProduct } from '@/types/types';
import { useUi } from '@/contexts/ui.context';
import { Cart } from '@/components/custom/cart';
import { Level } from '@/lib/api/types/api.enums';
import { DialogClose, DialogDescription, DialogTitle, DialogContent, DialogFooter, DialogHeader } from '@/components/ui/dialog';
import { DrawerClose, DrawerContent, DrawerDescription, DrawerFooter, DrawerHeader, DrawerTitle } from '@/components/ui/drawer';
import { useTranslations } from 'next-intl';
import CartBody from '@/components/custom/cart-body';

export const useCart = () => {
  const t = useTranslations();
  const dispatch: AppDispatch = useDispatch();
  const { isDesktop, showDialog, showDrawer, hideDialog, hideDrawer, showToast, hideToast } = useUi();
  const { items, totalAmount } = useSelector((state: RootState) => state.cart);
  const isCartEmpty = items.length === 0;

  const addToCart = (product: IProduct) => dispatch(addItem(product));
  const removeFromCart = (productId: number) => dispatch(removeItem(productId));

  const handleCheckout = useCallback((products: IProduct[]) => {

  }, []);

  const handleIncrement = useCallback((product: IProduct) => {
    addToCart(product);
  }, []);

  const handleDecrement = useCallback((product: IProduct) => {
    removeFromCart(product.id);
  }, []);

  const handleRemove = useCallback((product: IProduct) => {
    removeFromCart(product.id);
  }, []);

  function getCartContentComponent(isDesktop: boolean, items: IProduct[]): ReactNode {
    return (
      <>
        {isDesktop ? (
          <>
            <DialogHeader>
              <DialogTitle>{t('cart_title')}</DialogTitle>
              <DialogDescription>{t('cart_description')}</DialogDescription>
            </DialogHeader>
            <CartBody items={items} />
            <DialogFooter>
              <DialogClose>{t('cart_close')}</DialogClose>
            </DialogFooter>
          </>
        ) : (
          <>
            <DrawerHeader>
              <DrawerTitle>{t('cart_title')}</DrawerTitle>
              <DrawerDescription>{t('cart_description')}</DrawerDescription>
            </DrawerHeader>
            <CartBody items={items} />
            <DrawerFooter>
              <DrawerClose>{t('cart_close')}</DrawerClose>
            </DrawerFooter>
          </>
        )}
      </>
    )
  }

  const openCart = useCallback(() => {
    if (isDesktop === true) {
      showDialog(getCartContentComponent(isDesktop, items));
    } else {
      showDrawer(getCartContentComponent(isDesktop, items));
    }
  }, [isDesktop, items, showDialog, showDrawer, getCartContentComponent]);

  const closeCart = useCallback(() => {
    hideDialog();
    hideDrawer();
  }, [hideDialog, hideDrawer]);

  const toggleCart = useCallback((product: IProduct) => {
    if (isInCart(product.id)) {
      removeFromCart(product.id);
      hideToast(`cart-action-${product.id}`);
    } else {
      addToCart(product);
      showToast(`cart-action-${product.id}`, `Added. #${product.id}`, Level.SUCCESS);
    }
  }, [addToCart, removeFromCart]);

  const clearCart = () => {
    if (!isCartEmpty) {
      items.forEach((item: { id: number }) => {
        removeFromCart(item.id);
      });
    }
  };

  const isInCart = useCallback((productId: number): boolean => {
    if (items.length === 0) {
      return false;
    }

    return items.some((item: { id: number }) => item.id === productId);
  }, [items]);

  return {
    isCartEmpty,
    openCart,
    closeCart,
    isInCart,
    addToCart,
    removeFromCart,
    toggleCart,
    clearCart,
  };
};
