export const endpoints = {
  auth: {
    LOGIN: 'auth/login',
    LOGOUT: 'auth/logout',
    REGISTER: 'auth/register',
    GET_TOKENS: 'auth/get-tokens',
    GET_USER: 'auth/get-user',
    CHECK_EMAIL_ACCESSIBILITY: 'auth/check-email-accessibility',
  },
  combined: {
    GET_INITIAL_USER_DATA: 'combined/get-initial-data',
  },
  content: {
    collections: {
      INDEX: 'content/collections',
      SHOW: 'content/collections/show'
    },
    products: {
      INDEX: 'content/products/',
      SHOW: 'content/products/show',
      GET_BY_IDS: 'content/products/get-by-ids',
      GET_DETAILS: 'content/products/get-details',
      GET_VARIANTS: 'content/products/get-variants',
      GET_PRODUCT_DETAILS: 'content/products/get-product-details',
      GET_PRODUCT_VARIANTS: 'content/products/get-product-variants',
      GET_RECOMMENDED: 'content/products/get-recommended',
      GET_FILTERED: 'content/products/get-filtered',
      GET_BY_CATEGORY: 'content/products/get-by-category',
      GET_PRODUCT_SEO: 'content/products/get-product-seo',
    },
    taxonomy: {
      GET_CATEGORIES: 'content/taxonomy/get-categories',
      GET_ROOT_CATEGORIES: 'content/taxonomy/get-root-categories',
      GET_CHILDREN_CATEGORIES: 'content/taxonomy/get-sub-categories',
      GET_CATEGORY_SEO: 'content/taxonomy/get-category-seo',
    }
  },
  service: {
    logger: {
      SEND_REPORT: 'service/logger/handle-client-report',
    },
    search: {
      GET_RESULTS: 'service/search',
    }
  },
  user: {
    dashboard: {
      INDEX: 'user/dashboard/',
    },
    orders: {
      INDEX: 'user/orders/',
      SHOW: 'user/orders/show',
    },
    products: {
      INDEX: 'user/products/',
      SHOW: 'user/products/show',
    }
  },
}