'use client';

import { endpoints } from "@/config/endpoints";
import { buildApiUrl, buildRequest } from "../http/http.index";
import { formatLogMessage } from "./logger.index";
import { Level } from "../types/api.enums";

const sendReport = (message: string, level: Level, context?: Record<string, unknown>, sendToTelegram?: boolean) => {
  try {
    const apiUrl = buildApiUrl(endpoints.service.logger.SEND_REPORT);

    const headers: Headers = new Headers({
      'Content-Type': 'application/json',
      'X-App-Origin': 'client',
      'X-App-Mode': process.env.NEXT_PUBLIC_NODE_ENV ?? 'N/A',
    });

    const request = buildRequest('POST', headers, null, { message, level, context: context ?? {}, sendToTelegram: sendToTelegram ?? false });
    fetch(apiUrl, request);
  } catch (error: unknown) {
    console.error('Sending client log report failed!', error);
  }
}

export const logCritical = (message: unknown, context?: Record<string, unknown>) => {
  sendReport(formatLogMessage(message), Level.CRITICAL, context, true);
}

export const logError = (message: unknown, context?: Record<string, unknown>) => {
  sendReport(formatLogMessage(message), Level.ERROR, context, true);
}

export const logWarning = (message: unknown, context?: Record<string, unknown>) => {
  sendReport(formatLogMessage(message), Level.WARNING, context, false);
}

export const logInfo = (message: string, context?: Record<string, unknown>) => {
  sendReport(message, Level.INFO, context, false);
}

export const logSuccess = (message: string, context?: Record<string, unknown>) => {
  sendReport(message, Level.SUCCESS, context, true);
}

export const logDebug = (message: string, context?: Record<string, unknown>) => {
  sendReport(message, Level.DEBUG, context, false);
}