import { logError } from '@/lib/api/logger/logger.client';
import type { IProduct } from '@/types/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface CartItem extends IProduct {
  quantity: number;
}

interface CartState {
  items: CartItem[];
  totalAmount: number;
  totalItems: number;
}

const loadCartFromLocalStorage = (): CartState => {
  if (typeof window !== 'undefined') {
    try {
      const storedCart = localStorage.getItem('w_cart');
      if (storedCart) {
        return JSON.parse(storedCart);
      }
    } catch (error: unknown) {
      logError(error);
    }
  }
  return { items: [], totalAmount: 0, totalItems: 0 };
};

const saveCartToLocalStorage = (state: CartState) => {
  if (typeof window !== 'undefined') {
    try {
      localStorage.setItem('w_cart', JSON.stringify(state));
    } catch (error: unknown) {
      logError(error);
    }
  }
};

const initialState: CartState = loadCartFromLocalStorage();

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addItem: (state, action: PayloadAction<IProduct>) => {
      const existingItem = state.items.find(
        (item) => item.id === action.payload.id,
      );

      if (existingItem) {
        existingItem.quantity += 1;
      } else {
        state.items.push({ ...action.payload, quantity: 1 });
      }

      state.totalAmount += action.payload.price.current;
      state.totalItems += 1;
      saveCartToLocalStorage(state);
    },

    decreaseItem: (state, action: PayloadAction<number>) => {
      const itemIndex = state.items.findIndex(
        (item) => item.id === action.payload,
      );

      if (itemIndex !== -1) {
        const item = state.items[itemIndex];
        if (item) {
          if (item.quantity > 1) {
            item.quantity -= 1;
            state.totalAmount -= item.price.current;
            state.totalItems -= 1;
          } else {
            state.items.splice(itemIndex, 1);
            state.totalAmount -= item.price.current;
            state.totalItems -= 1;
          }
        }
      }

      saveCartToLocalStorage(state);
    },

    removeItem: (state, action: PayloadAction<number>) => {
      const itemIndex = state.items.findIndex(
        (item) => item.id === action.payload,
      );

      if (itemIndex !== -1) {
        const item = state.items[itemIndex];

        if (item) {
          state.totalAmount -= item.price.current * item.quantity;
          state.totalItems -= item.quantity;
          state.items.splice(itemIndex, 1);
        }
      }

      saveCartToLocalStorage(state);
    },

    clearCart: (state) => {
      state.items = [];
      state.totalAmount = 0;
      state.totalItems = 0;
      saveCartToLocalStorage(state);
    },
  },
});

export const { addItem, removeItem, decreaseItem, clearCart } =
  cartSlice.actions;
export default cartSlice.reducer;
