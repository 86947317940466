export function formatLogMessage(message: unknown): string {
  if (message instanceof Error) {
    return message.message;
  } else if (typeof message === 'string') {
    return message;
  } else if (typeof message === 'object' && message !== null) {
    try {
      return JSON.stringify(message);
    } catch {
      return 'Unserializable object';
    }
  } else if (typeof message === 'number' || typeof message === 'boolean') {
    return String(message);
  } else {
    return 'Unknown error type';
  }
}
