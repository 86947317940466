export const appConfig = {
  locales: ['en', 'ru'],
  defaultLocale: 'en',
  appUrl: 'https://wlampi.com',
  apiUrl: 'https://api.tgonlineweb.xyz/api',
  shopify: {
    STOREFRONT_API_URL: 'https://x8upb5-ru.myshopify.com/api/2025-01/graphql.json',
    STOREFRONT_API_TOKEN: '7d8dc67213b31d5b6706f9bbd1c0df9c',
  },
  cookies: {
    keys: {
      ACCESS_TOKEN: 'w_access_token',
      REFRESH_TOKEN: 'w_refresh_token',
      LOCALE: 'w_locale',
      CART: 'w_cart',
      WATCHLIST: 'w_watchlist',
      RECENTLY_VIEWED: 'w_recentlty_viewed',
    },
    lifetimeDays: {
      access_token: 15,
      refresh_token: 150,
      locale: 150,
      cart: 150,
      watchlist: 150,
      recent: 150,
    }
  }
}

export const settings = {
  logo: {
    url: 'assets/images/logo2.svg',
    alt: 'Wlampi',
    href: '/',
    width: 48,
    height: 48,
  },
  discountDisplayThreshold: -3,
  header: {

  }
}