
export enum ProductAttributeType {
  FIT = 'fit',
  AGE_GROUP = 'age_group',
  FEATURED = 'featured',
  OCCASION = 'occasion',
  OTHER = 'other',
  MATERIAL = 'material',
  NECK_STYLE = 'neck_style',
  COLAR_STYLE = 'colar_style',
}

export enum Color {
  BLACK = 'black',
  WHITE = 'white',
  GREEN = 'green',
  RED = 'red',
  YELLOW = 'yellow',
  BLUE = 'blue',

}

export enum Gender {
  MALE = 'male',
  FEMALE = 'female',
  UNISEX = 'unisex',
}

export enum AgeGroup {
  
}

export enum Material {

}

export enum Fit {
  
}

export enum Occasion {

}

export enum NeckStyle {
  
}

export enum ColarStyle {

}