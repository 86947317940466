'use client';

import { IProduct } from "@/types/types";
import { ShoppingCartIcon } from "lucide-react";

type CartBodyProps = {
  items: IProduct[];
}

const CartBody = ({ items }: CartBodyProps) => {
  return (
    <>
      {items.length > 0 ? (
        items.map(product => (
          <div key={product.id}>
            {product.name}
          </div>
        ))
      ) : (
        <ShoppingCartIcon />
      )}
    </>
  )
}

export default CartBody;
