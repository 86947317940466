import { IProductFilters } from "@/types/types";
import { IProductFlagFilter } from '@/types/types';
import { Color } from '@/types/enums';

export const buildSearchQueryLink = (query: string): string => `/search?q=${encodeURIComponent(query)}`;
export const buildCollectionLink = (collectionId: number, slug: string): string => `/collection/${encodeURIComponent(slug)}?id=${collectionId}`;
export const buildProductUrl = (productId: number, slug: string) => `/item/${encodeURIComponent(slug)}?id=${productId}`;

export const buildCategoryLink = (categorySlug: string, categoryId: number | StringConstructor): string => {
  return `/categories/${encodeURIComponent(categorySlug)}?id=${categoryId}`;
}

export function transformProductFiltersQuery(filters: IProductFilters): Record<string, string | number | boolean> {
  const result: Record<string, string | number | boolean> = {};

  if (filters.q) {
    result.q = filters.q;
  }

  if (filters.filters) {
    Object.entries(filters.filters).forEach(([key, value]) => {
      if (value) {
        result[key] = true;
      }
    });
  }

  return result;
}

export const parseSearchParams = (params: Record<string, any>) => {
  const filters = (params.filters?.split(',') ?? []).map((flag: string) => ({ flag })) as IProductFlagFilter[];
  const colors = (params.colors?.split(',') ?? []).filter((c: Color): c is Color => Object.values(Color).includes(c as Color));

  return {
    q: params.q ?? '',
    filters,
    colors,
    min: params.min ? Number(params.min) : undefined,
    max: params.max ? Number(params.max) : undefined,
  };
};