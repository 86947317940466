'use client';

import { appConfig } from "@/config";
import { getCookie } from "../cookie/cookie.client";
import { ApiError } from "../exceptions/api-error";
import { IApiResponse, IHttpMethod, IBuildHeadersOptions } from "../types/api.types";
import { fetchApiResponse } from "./http.index";

const cookieNames = appConfig.cookies.keys;

export const sendRequest = async <T>(apiUrl: string, request: RequestInit, controller: AbortController, timeout?: number): Promise<IApiResponse<T>> => {
  const timeoutId = setTimeout(() => controller.abort(), timeout);

  try {
    const response = await fetch(apiUrl, request);
    return fetchApiResponse<T>(response);
  } catch (error: unknown) {
    if (error instanceof DOMException && error.name === 'AbortError') {
      throw new Error(`API timeout: ${apiUrl}`);
    }

    if (error instanceof Error) {
      throw new Error(`Client request failed: ${apiUrl}`);
    }

    throw new ApiError(`API (client) error occurred: ${apiUrl}`);
  } finally {
    clearTimeout(timeoutId);
  }
}

export const buildHeaders = (method: IHttpMethod, options?: IBuildHeadersOptions): Headers => {
  const headers: Headers = new Headers();

  if (method === 'POST' || method === 'DELETE') {
    headers.append('Content-Type', 'application/json');
  }

  if (options) {
    if (options.withAccessToken) {
      const accessToken = getCookie(cookieNames.ACCESS_TOKEN);
      if (accessToken) {
        headers.append('Authorization', `Bearer ${accessToken}`);
      }
    }

    if (options.withStoredLanguage) {
      const storedLocale = getCookie(cookieNames.LOCALE);
      if (storedLocale) {
        headers.append('Accept-Language', storedLocale);
      }
    }

    if (options.preferredLanguage) {
      headers.append('X-User-Preferred-Language', options.preferredLanguage);
    }
  }

  return headers;
};
