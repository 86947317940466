'use client';

import { IProduct } from '@/types/types';
import { createContext, useContext } from 'react';

export interface WatchlistContextType {
  watchlistIds: number[];
  watchlistCount: number;
  watchlistProducts: IProduct[];
  isLoading: boolean;
  isError: boolean;
  isInWatchlist: (productId: number) => boolean;
  addProductToWatchlist: (product: IProduct) => void;
  removeProductFromWatchlist: (productId: number) => void;
  toggleWatchlist: (product: IProduct) => void;
  loadWatchlistProducts: (productIds: number[]) => void;
}

export const WatchlistContext = createContext<WatchlistContextType | undefined>(undefined);

export const useWatchlist = (): WatchlistContextType => {
  const context = useContext(WatchlistContext);
  if (!context) {
    throw new Error('useWatchlist must be used within a WatchlistProvider');
  }
  return context;
};
