'use client';

import { Level } from '@/lib/api/types/api.enums';
import { createContext, ReactNode, useContext } from 'react';

export interface UiContextType {
  isDesktop: boolean;
  showModal: (content: ReactNode) => void;
  hideModal: () => void;
  showDialog: (content: ReactNode) => void;
  hideDialog: () => void;
  showDrawer: (content: ReactNode) => void;
  hideDrawer: () => void;
  showSheet: (content: ReactNode) => void;
  hideSheet: () => void;
  showResponsive: (content: ReactNode) => void;
  hideResponsive: () => void;
  showToast: (id: string, title: string, level: Level) => void;
  hideToast: (toastId: string) => void;
}

export const UiContext = createContext<UiContextType | undefined>(undefined);

export const useUi = (): UiContextType => {
  const context = useContext(UiContext);
  if (!context) {
    throw new Error('useUi must be used within a UiProvider');
  }
  return context;
};
