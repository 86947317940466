import { IApiException } from "../types/api.types";

export class ApiException extends Error {

  constructor(apiData: IApiException) {
    super(`API exception occurred: ${apiData.message}.`);
    this.message = apiData.message;
    this.name = 'ApiException';
  }
}
