import { appConfig } from "@/config";
import { ApiError } from "../exceptions/api-error";
import { ApiException } from "../exceptions/api-exception";
import { IHttpMethod, IApiResponse, IApiException } from "../types/api.types";

export function buildApiUrl(
  apiEndpoint: string,
  params?: Record<string, string | number | boolean | null | undefined> | null
): string {
  const sanitizedParams = Object.entries(params || {}).reduce((acc, [key, value]) => {
    if (value !== null && value !== undefined) {
      acc[key] = String(value);
    }
    return acc;
  }, {} as Record<string, string>);

  const queryString = new URLSearchParams(sanitizedParams).toString();

  const endpoint = queryString ? `${apiEndpoint}?${queryString}` : apiEndpoint;

  return `${appConfig.apiUrl.replace(/\/+$/, "")}/${endpoint.replace(/^\/+/, "")}`;
}

export function buildRequest(method: IHttpMethod, headers: Headers, signal?: AbortSignal | null, body?: object | FormData | null): RequestInit {
  const request: RequestInit = { headers, method, credentials: 'include', signal };

  if (body) {
    if (body instanceof FormData) {
      request.body = body;
    } else {
      request.body = JSON.stringify(body);
    }
  }

  return request;
}

export function handleApiResponse<T>(apiResponse: IApiResponse<T | IApiException>): T {
  if (!apiResponse.success) {
    throw new ApiException(apiResponse.data as IApiException)
  }

  return apiResponse.data as T;
}

export async function fetchApiResponse<T>(response: Response): Promise<IApiResponse<T>> {
  const url = response.url;

  if (!response.ok) {
    throw new ApiError(response.statusText, response.status, url);
  }

  try {
    const json = await response.json();
    return json as IApiResponse<T>;
  } catch (error: unknown) {
    const responseText = await response.text();
    const message = error instanceof SyntaxError ? 'Failed to parse JSON response.' : 'Unknown API parsing error.';
    throw new ApiError(message, response.status, url, { body: responseText });
  }
}